<!-- CartSizeConfirmation.vue -->
<template>
    <div class="cart-confirmation">
        <!-- Header -->
        <div class="header">
            <button @click="$router.push('/customer-service')" class="back-button">
                Back to Service Options
            </button>
            <h1>Cart Size Confirmation</h1>
            <div class="view-toggle">
                <button :class="['toggle-btn', currentView === 'pending' ? 'active' : '']"
                    @click="currentView = 'pending'">
                    Pending ({{ filteredPendingAddresses.length }})
                </button>
                <button :class="['toggle-btn', currentView === 'confirmed' ? 'active' : '']"
                    @click="currentView = 'confirmed'">
                    Confirmed ({{ filteredConfirmedAddresses.length }})
                </button>
            </div>
        </div>

        <!-- Search Bar -->
        <div class="search-container">
            <div class="search-input-wrapper">
                <!-- ... existing search input ... -->
            </div>
            <div class="live-updates">
                <div class="status-dot"></div>
                <span>Refresh List </span>
                <button @click="refreshData" :disabled="loading" class="refresh-button">
                    <svg :class="{ 'spin': loading }" viewBox="0 0 24 24" width="18" height="18">
                        <path fill="currentColor"
                            d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" />
                    </svg>
                </button>
            </div>
        </div>

        <!-- Loading State -->
        <div v-if="loading" class="loading-state">
            <div class="spinner"></div>
            <p>Loading addresses...</p>
        </div>

        <template v-else>
            <!-- Search Bar -->
            <div class="search-container">
                <div class="search-input-wrapper">
                    <input type="text" v-model="searchQuery" placeholder="Search by address..." class="search-input" />
                    <button v-if="searchQuery" @click="searchQuery = ''" class="clear-search">
                        ×
                    </button>
                </div>

            </div>

            <!-- Address List -->
            <div class="address-list">
                <template v-if="currentView === 'pending'">
                    <div v-for="address in filteredPendingAddresses" :key="address.RecordID" class="address-card">
                        <div class="pin-icon">
                            <svg viewBox="0 0 24 24" width="40" height="40">
                                <path
                                    d="M12 0C7.6 0 4 3.6 4 8c0 5.3 8 16 8 16s8-10.7 8-16c0-4.4-3.6-8-8-8zm0 12c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4z"
                                    fill="currentColor" />
                            </svg>
                        </div>

                        <div class="address-details">
                            <div class="street">{{ address.PrimarySiteAddress }}</div>
                            <div class="name">{{ address.ContactFirstName }} {{ address.ContactLastName }}</div>
                            <div class="site-info">Site ID: {{ address.RoutingSiteID }}</div>

                            <div class="cart-info">
                                Current Cart Size: {{ address.ServiceSizeBefore }}-Gallon
                                ({{ getServiceTypeLabel(address.ServiceTypeCode) }})
                            </div>


                            <div class="confirmation-controls">
                                <select v-model="address.confirmedSize" class="cart-size-select">
                                    <option value="">Select Cart Size</option>
                                    <option v-for="size in cartSizes" :key="size" :value="size"
                                        :selected="size === address.ServiceSizeBefore">
                                        {{ size }} Gallon
                                    </option>
                                </select>

                                <button @click="confirmAddress(address)"
                                    :disabled="!address.confirmedSize || confirmingAddress" class="confirm-button">
                                    {{ confirmingAddress ? 'Confirming...' : 'Confirm Size' }}
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- Empty State -->
                    <div v-if="filteredPendingAddresses.length === 0" class="empty-state">
                        {{ searchQuery ? 'No addresses match your search' : 'No pending addresses to confirm' }}
                    </div>
                </template>

                <template v-else>
                    <div v-for="address in filteredConfirmedAddresses" :key="address.RecordID"
                        class="address-card confirmed">
                        <div class="pin-icon">
                            <svg viewBox="0 0 24 24" width="40" height="40">
                                <path
                                    d="M12 0C7.6 0 4 3.6 4 8c0 5.3 8 16 8 16s8-10.7 8-16c0-4.4-3.6-8-8-8zm0 12c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4z"
                                    fill="currentColor" />
                            </svg>
                        </div>

                        <div class="address-details">
                            <div class="street">{{ address.PrimarySiteAddress }}</div>
                            <div class="location">Site #: {{ address.RoutingSiteID }}, Contact : {{
                                address.ContactFirstName }} {{ address.ContactLastName }}</div>
                            <div class="confirmed-info">
                                <div class="confirmed-size">
                                    Cart Size: {{ address.NewSizeConfirmedbyLRS || address.ServiceSizeAfter }}-Gallon
                                    ({{ getServiceTypeLabel(address.ServiceTypeCode) }})
                                </div>
                                <div class="confirmation-details">
                                    Confirmed on {{ formatDate(address.RecordTimestamp) }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Empty State -->
                    <div v-if="filteredConfirmedAddresses.length === 0" class="empty-state">
                        {{ searchQuery ? 'No addresses match your search' : 'No confirmed addresses yet' }}
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

// CartSizeConfirmation.vue
<script>
import { ref, computed, onMounted } from 'vue'
import { useCache } from '../utils/cacheService'




export default {
    name: 'CartSizeConfirmation',

    setup() {
        // State
        const currentView = ref('pending')
        const searchQuery = ref('')
        const addresses = ref([])
        const confirmingAddress = ref(false)
        const error = ref(null)
        const cartSizes = ref(['35', '64', '96'])
        const pageSize = ref(20) // For progressive loading

        // Initialize cache service
        const { fetchWithCache, loading, clearCache } = useCache({
            ttl: 5 * 60 * 1000, // 5 minutes cache
            cacheKey: 'cart-confirmation-cache',
            version: '1.0'
        })

        // Computed properties for filtered addresses
        const filteredPendingAddresses = computed(() => {
            return addresses.value
                .filter(addr => !addr.ConfirmedCartSizebyLRS)
                .filter(addr =>
                    searchQuery.value === '' ||
                    formatName(addr).toLowerCase().includes(searchQuery.value.toLowerCase()) ||
                    addr.PrimarySiteAddress.toLowerCase().includes(searchQuery.value.toLowerCase())
                )
                .slice(0, pageSize.value) // Progressive loading
        })

        const filteredConfirmedAddresses = computed(() => {
            return addresses.value
                .filter(addr => addr.ConfirmedCartSizebyLRS)
                .filter(addr =>
                    searchQuery.value === '' ||
                    formatName(addr).toLowerCase().includes(searchQuery.value.toLowerCase()) ||
                    addr.PrimarySiteAddress.toLowerCase().includes(searchQuery.value.toLowerCase())
                )
                .slice(0, pageSize.value) // Progressive loading
        })

        // Fetch addresses with cache support
        const fetchAddresses = async (forceRefresh = false) => {
            try {
                // Function to fetch both pending and confirmed addresses
                const fetchAllAddresses = async () => {
                    const [pendingResponse, confirmedResponse] = await Promise.all([
                        fetch(process.env.VUE_APP_GETCONFIRMATIONCARTS, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ confirmed: false })
                        }),
                        fetch(process.env.VUE_APP_GETCONFIRMATIONCARTS, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ confirmed: true })
                        })
                    ])

                    if (!pendingResponse.ok || !confirmedResponse.ok) {
                        throw new Error('Failed to fetch addresses')
                    }

                    const [pendingData, confirmedData] = await Promise.all([
                        pendingResponse.json(),
                        confirmedResponse.json()
                    ])

                    // Initialize confirmedSize with current size
                    const initializedPendingData = pendingData.map(address => ({
                        ...address,
                        confirmedSize: address.ServiceSizeBefore.toString()
                    }))

                    return [...initializedPendingData, ...confirmedData]
                }

                // If forceRefresh, clear cache first
                if (forceRefresh) {
                    clearCache()
                }

                // Fetch with cache
                const data = await fetchWithCache(fetchAllAddresses)
                addresses.value = data
            } catch (err) {
                error.value = err.message
                console.error('Error fetching addresses:', err)
            }
        }

        // Confirm address
        const confirmAddress = async (address) => {
            confirmingAddress.value = true
            try {
                const payload = {
                    recordId: address.RecordID,
                    confirmed: true,
                    newSizeByLRS: address.confirmedSize !== address.ServiceSizeBefore.toString()
                        ? address.confirmedSize
                        : address.ServiceSizeBefore.toString()
                }

                const response = await fetch(process.env.VUE_APP_CONFIRMCARTSIZE, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload)
                })

                if (!response.ok) throw new Error('Failed to confirm address')

                // Update local state
                const index = addresses.value.findIndex(a => a.RecordID === address.RecordID)
                if (index !== -1) {
                    addresses.value[index] = {
                        ...addresses.value[index],
                        ServiceSizeAfter: address.confirmedSize,
                        ConfirmedCartSizebyLRS: true,
                        RecordTimestamp: new Date().toISOString()
                    }
                }

                // Clear cache after successful confirmation
                clearCache()
            } catch (err) {
                error.value = err.message
                console.error('Error confirming address:', err)
            } finally {
                confirmingAddress.value = false
            }
        }

        // Pull to refresh functionality
        const refreshData = async () => {
            try {
                await fetchAddresses(true) // Force refresh
                // Optional: Show success feedback
            } catch (err) {
                error.value = 'Failed to refresh data'
            }
        }

        // Load more items
        const loadMore = () => {
            pageSize.value += 20
        }

        // Utility functions
        const formatName = (address) => {
            return `${address.ContactFirstName} ${address.ContactLastName}`
        }

        const getServiceTypeLabel = (code) => {
            const types = {
                'RG': 'Waste',
                'RR': 'Recycle',
                'RY': 'Yard Waste'
            }
            return types[code] || code
        }

        const formatDate = (dateString) => {
            return new Intl.DateTimeFormat('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }).format(new Date(dateString))
        }

        // Setup infinite scroll
        const setupInfiniteScroll = () => {
            const observer = new IntersectionObserver(
                (entries) => {
                    if (entries[0].isIntersecting) {
                        loadMore()
                    }
                },
                { threshold: 0.5 }
            )

            // Observe the last item
            const observeLastItem = () => {
                const lastItem = document.querySelector('.address-card:last-child')
                if (lastItem) {
                    observer.observe(lastItem)
                }
            }

            // Setup observation after mount and updates
            onMounted(() => {
                observeLastItem()
            })
        }

        // Initialize
        onMounted(() => {
            fetchAddresses()
            setupInfiniteScroll()
        })

        return {
            // State
            currentView,
            searchQuery,
            loading,
            confirmingAddress,
            error,
            cartSizes,

            // Computed
            filteredPendingAddresses,
            filteredConfirmedAddresses,

            // Methods
            confirmAddress,
            formatName,
            getServiceTypeLabel,
            formatDate,
            refreshData,
            loadMore
        }
    }
}
</script>

<style scoped>
.cart-confirmation {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.header {
    margin-bottom: 20px;
}

.header h1 {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0;
}

.back-button {
    background: none;
    border: none;
    color: #666;
    padding: 0;
    font-size: 14px;
    cursor: pointer;
}

.view-toggle {
    display: flex;
    gap: 10px;
    margin: 15px 0;
}

.toggle-btn {
    padding: 8px 16px;
    border: 1px solid #ddd;
    background: white;
    border-radius: 4px;
    cursor: pointer;
}

.toggle-btn.active {
    background: #007bff;
    color: white;
    border-color: #007bff;
}

.search-container {
    margin-bottom: 20px;
}

.live-updates {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #666;
}

.refresh-button {
    background: none;
    border: none;
    padding: 6px;
    cursor: pointer;
    color: #666;
    transition: transform 0.3s ease;
    border-radius: 50%;
    transition: all 0.3s ease;
}

.refresh-button:hover {
    background: #f0f0f0;
    transform: scale(1.1);
}

.refresh-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.refresh-button svg.spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.status-dot {
    width: 8px;
    height: 8px;
    background: #4CAF50;
    border-radius: 50%;
    animation: pulse 2s infinite;
}

.address-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.address-card {
    display: flex;
    gap: 20px;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pin-icon {
    color: #666;
    flex-shrink: 0;
}

.address-details {
    flex: 1;
}

.street {
    font-size: 18px;
    font-weight: 500;
}

.location {
    color: #666;
    margin: 4px 0;
}

.site-number {
    color: #666;
    font-size: 14px;
}

.confirmation-controls {
    display: flex;
    gap: 10px;
    margin-top: 15px;
}

.cart-size-select {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.confirm-button {
    padding: 8px 16px;
    background: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.confirm-button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.confirmed-info {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #eee;
}

.confirmed-size {
    font-weight: 500;
    color: #4CAF50;
}

.confirmation-details {
    font-size: 14px;
    color: #666;
    margin-top: 4px;
}

.empty-state {
    text-align: center;
    padding: 40px;
    color: #666;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@keyframes pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@media (max-width: 640px) {
    .confirmation-controls {
        flex-direction: column;
    }

    .address-card {
        flex-direction: column;
        gap: 10px;
    }

    .pin-icon {
        align-self: center;
    }
}


.loading-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
}

.search-input-wrapper {
    position: relative;
    margin-bottom: 10px;
}

.search-input {
    width: 100%;
    padding: 12px;
    padding-right: 40px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.clear-search {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 20px;
    color: #666;
    cursor: pointer;
}

.cart-info {
    color: #666;
    font-size: 14px;
    margin: 4px 0;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Rest of the existing styles... */
</style>