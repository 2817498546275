// cacheService.js
import { ref } from 'vue'

class CacheService {
    constructor(options = {}) {
        this.ttl = options.ttl || 5 * 60 * 1000 // 5 minutes default TTL
        this.cacheKey = options.cacheKey || 'cart-confirmation-cache'
        this.version = options.version || '1.0'
    }

    // Get data from cache
    async getCachedData() {
        try {
            const cacheData = localStorage.getItem(this.cacheKey)
            if (!cacheData) return null

            const { timestamp, version, data } = JSON.parse(cacheData)
            
            // Validate version and TTL
            if (version !== this.version || Date.now() - timestamp > this.ttl) {
                localStorage.removeItem(this.cacheKey)
                return null
            }

            return data
        } catch (error) {
            console.warn('Cache read error:', error)
            return null
        }
    }

    // Set data to cache
    async setCachedData(data) {
        try {
            const cacheData = {
                timestamp: Date.now(),
                version: this.version,
                data
            }
            localStorage.setItem(this.cacheKey, JSON.stringify(cacheData))
        } catch (error) {
            console.warn('Cache write error:', error)
        }
    }

    // Clear cache
    clearCache() {
        localStorage.removeItem(this.cacheKey)
    }
}

// Create composable for Vue components
export function useCache(options = {}) {
    const cacheService = new CacheService(options)
    const loading = ref(false)
    const error = ref(null)

    // Fetch data with cache support
    async function fetchWithCache(fetchFn) {
        loading.value = true
        error.value = null

        try {
            // Try to get data from cache first
            const cachedData = await cacheService.getCachedData()
            if (cachedData) {
                loading.value = false
                return cachedData
            }

            // If no cache, fetch fresh data
            const freshData = await fetchFn()
            
            // Cache the new data
            await cacheService.setCachedData(freshData)
            
            return freshData
        } catch (err) {
            error.value = err
            throw err
        } finally {
            loading.value = false
        }
    }

    return {
        fetchWithCache,
        clearCache: cacheService.clearCache.bind(cacheService),
        loading,
        error
    }
}