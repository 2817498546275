<template>



  <div class="elgin-resident-portal">
    <!-- Section 1 -->
    <section class="responsive-section">
      <div class="column column-1">
        <h1 class="h1">Welcome to LRS!</h1>
        <p class="paragraph">
          We are honored to be your new service provider beginning January 1, 2025! We are committed to providing you
          with timely, reliable and sustainable services. We are excited to announce some upcoming changes with the new
          program!
        </p><br>
        <h3 class="h3">2025 CART DELIVERY SCHEDULE</h3>
        <p><strong>Schedule for:</strong>
          <ul>
            <li>New organic food scrap and yard waste cart requests</li>
            <li>New cart size exchanges (downsizing to 35 gallons, upsizing to 96 gallons, and 64-gallon cart requests) </li>
            <li>Additional service cart requests </li>
          </ul>
        </p>
          <br>
        <div class="grid-container">
    <div class="grid-header">Request Deadline</div>
    <div class="grid-header">Cart Deliveries Start</div>
    <div class="grid-header">Cart Deliveries End</div>

    <div class="grid-item">January 20, 2025</div>
    <div class="grid-item">March 3, 2025</div>
    <div class="grid-item">March 28, 2025</div>

    <div class="grid-item">April 16, 2025</div>
    <div class="grid-item">June 2, 2025</div>
    <div class="grid-item">June 27, 2025</div>
  </div><br>
  <p>LRS will notify you of the expected exchange or delivery time through your preferred contact method shared during enrollment.</p>
<br>

  <p><strong>CART EXCHANGE TIPS</strong></p>
  <ul>
    <li>Cart exchanges will happen Monday through Friday.</li>
    <li>If you requested an exchange after January 20, it is scheduled for completion in June.</li>
    <li>City Code Compliance is aware of the transition and will not issue citations for scheduled households.</li>
    <li>Cart exchanges are conducted by specialized crews, not your regular drivers.</li>
    <li>Cart exchanges for downsizing or upsizing will take place in March on your regular pickup day, following the removal of weekly materials.</li>
  </ul>
  <br>

        <div class="accordion-container">
          <div class="accordion-item">
            <div class="accordion-header">
              <div class="icon"><img src="@/assets/rescart.png" alt="Recycle Guide" class="elgin-image" style="width: auto; height: auto;"></div>
              <span><h3 class="h3">WHAT'S STAYING THE SAME?</h3></span>
              <div class="toggle-icon">+</div>
            </div>
            <div class="accordion-content">
              <ul>
                <li>Collection day will stay the same</li>
                <li>City of Elgin gray and blue carts will still be used</li>
                <li>Electronic and Household Hazardous Waste Collection will still be provided</li>
                <li>Base service will continue to be billed on your monthly utility bill</li>
              </ul><br>
            </div>
          </div>

          <div class="accordion-item">
            <div class="accordion-header">
              <div class="icon"><img src="@/assets/rescart.png" alt="Recycle Guide" class="elgin-image" style="width: auto; height: auto;"></div>
              <span><h3 class="h3">WHAT'S CHANGING?</h3></span>
              <div class="toggle-icon">+</div>
            </div>
            <div class="accordion-content">
              <ul>
                <li>Garbage, recycle and yard waste pickup times will vary</li>
                <li>Yard waste stickers are no longer required</li>
                <li>Free yard waste and organic carts are optional upon enrollment</li>
                <li>Limit of two (2) bulk items each week with stickers required</li>
                <li>Stickers will be required for garbage outside of Elgin and LRS carts</li>
                <li>WM trash stickers will be honored through March 31, 2025</li>
                <li>Program rates will be based on cart sizes</li>
                <li>Rate information available on this site</li>
              </ul><br>
            </div>
          </div>

          <div class="accordion-item">
            <div class="accordion-header">
              <div class="icon"><img src="@/assets/rescart.png" alt="Recycle Guide" class="elgin-image" style="width: auto; height: auto;"></div>
              <span><h3 class="h3">WHAT SIZE CART DO YOU HAVE?</h3></span>
              <div class="toggle-icon">+</div>
            </div>
            <div class="accordion-content">
              <img src="@/assets/cartsize.png" alt="LRS Truck" class="responsive-image">
            </div>            
          </div>

        </div>

        <h2 class="h2">CART OPTIONS</h2>

        <div class="accordion-container">
          <div class="accordion-item">
            <div class="accordion-header">
              <div class="icon"><img src="@/assets/YardSm.png" alt="Yard Waste" class="elgin-image" style="width: auto; height: auto;"></div>
              <span><h3a class="h3a"><strong>FREE OPTIONAL ORGANICS CART (FOOD SCRAPS & YARD WASTE)</strong></h3a></span>
              <div class="toggle-icon">+</div>
            </div>
            <div class="accordion-content">
              <p class="paragraph">
                Upon request, LRS provides <strong>one (1)</strong> complimentary yard waste and organics cart for each home. Additional carts are available for a monthly fee. See Extra Cart Services to submit a request for an additional cart.<br><br>
                Organics Cart (Food Scraps & Yard Waste) Available Sizes:
                <ul>
                  <li>35-Gallon</li>
                  <li>64-Gallon</li>
                  <li>96-Gallon</li>
                </ul>
              </p>

              <div class="accordion-item2">
        <div class="accordion-header">

          <span> <h3d class="h3d">What is Included in<br>Organics (Food Scraps & Yard Waste)?</h3d></span>
          <div class="toggle-icon">+</div>
        </div>
        <div class="accordion-content">
          <p class="paragraph">
  Starting January 1, 2025, yard waste and organic stickers will no longer be required. Weekly collection will take place from April 1 through the first full week of December, on your regular collection day.
  </p>
  <h2 class="subheading-1">YARD WASTE GUIDELINES</h2>
  <p class="paragraph">
  Yard waste must be contained in one of the following ways:<br>
  </p>
  <ul>
  <li>In an LRS-provided 35-gallon, 64-gallon or 96-gallon cart</li>
  <li>Biodegradable, 2-ply thickness brown landscape waste bag (50 lb. limit)</li>
  <li>In a 35-gallon customer-owned container with a 50 lb. limit</li>
  <li>Customer containers must have 2 handles</li>
  <li>Brush secured into bundles not exceeding 5 ft. in length, 2 ft. in diameter and 45 lbs.</li>
  </ul>
<br>
  <h2 class="subheading-1">ACCEPTED ORGANICS</h2>

  <ul>
  <li><strong>Food:</strong> Baked goods, Food waste, Grains, Produce</li>
  <li><strong>Non-Food Organics:</strong> Clay, Floral items</li>
  <li>Yard Waste</li>
  </ul>
  <br>
  <h2 class="subheading-1">UNACCEPTED MATERIALS</h2>

  <ul>
  <li><strong>Organic Waste:</strong> Animal waste, Dairy, Meat and bones</li>
  <li><strong>Paper Products:</strong> Bath tissues, Coffee filters, Newspapers, Paper, Paper bags, Biodegradable paper products</li>
  <li><strong>Plastic Items:</strong> Biodegradable plastic products, Plastic bags, Plastic containers, Styrofoam containers</li>
  <li><strong>Construction & Yard Waste:</strong> Bricks, Cardboard, Concrete, Rocks, Wood</li>
  <li><strong>Hazardous Materials:</strong> Grease, Liquids, Oil</li>
  <li><strong>Other:</strong> Diapers, Glass, Wipes, Metal</li>
  </ul>
  <br>
  <h2 class="subheading-1">HOLIDAY TREE REMOVAL</h2>
  <p class="paragraph">
  Live tree collection runs December 26 through January 17. Trees should be bare (no tinsel, lights or ornaments). Trees over 6 feet need to be cut down.
        </p>
        </div>
      </div>

      <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/form/yard-waste';">Request Free Optional<br>Organics | Food Scraps<br>& Yard Waste Cart</button><br><br>
            </div>





            
          </div>

          <div class="accordion-item">
            <div class="accordion-header">
              <div class="icon"><img src="@/assets/LandfillSm.png" alt="Garbage" class="elgin-image" style="width: auto; height: auto;"></div>
              <span><h3a class="h3a"><strong>REDUCED WASTE 35-GALLON CART OPTION</strong></h3a></span>
              <div class="toggle-icon">+</div>
            </div>
            <div class="accordion-content">
              <p class="paragraph">
                If you do not generate more then two (2) trash bags each week, this option may work for you. Additional trash placed outside of the cart requires Elgin Trash Sticker(s). Please select the <italic>Downsize Waste Cart to 35-Gallon</italic> button below if you'd like to make this change.<br><br>
                Billing will begin April 1, 2025, allowing ample time for cart deliveries and cart size verifications. Charges will appear on your utility-water bill from the City of Elgin.
                <br><br>
                <ul>
                  <li>35-Gallon: $22.50</li>
                  <li>64-Gallon: $24.95</li>
                </ul>
              </p><br>
              <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/form/sustainable';">Downsize to 35-Gallon Waste Cart Here</button><br><br>
            </div>
          </div>

          <div class="accordion-item">
            <div class="accordion-header">
              <div class="icon"><img src="@/assets/rescart.png" alt="Other Options" class="elgin-image" style="width: auto; height: auto;"></div>
              <span><h3a class="h3a"><strong>UPSIZE TO 96-GALLON & REQUEST ADDITIONAL CART(S)</strong></h3a></span>
              <div class="toggle-icon">+</div>
            </div>
            <div class="accordion-content">
              <p class="paragraph">
                LRS offers Elgin homes the option to up-size your base service waste cart to a 96-gallon for a monthly charge of $2.80. Residents may also request additional carts for Waste, Recycle, Yard Waste and Organics, upon request. Both are annually billed from LRS.<br><br>
                Monthly Rates Per Cart: (billed on an annual basis)
                <ul>
                  <li>64-Gallon: $15.95</li>
                  <li>96-Gallon: $18.75</li>
                </ul>
              </p><br>
              <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/form/cart-enrollment';">Upsize to 96-Gallon & Request Additional Cart(s)</button>
            </div>
          </div>
        </div>

        <div class="cart-confirmation-section">
                          <h3 class="subheading-1">
            If you have a 35-gallon cart
            <br> <strong>AND INTEND TO KEEP IT,</strong><br>
             please confirm below.
          </h3>
          <button 
            @click="showCartSizeModal = true" 
            class="confirm-size-button"
          >
            Click Here to Confirm Your 35-Gallon Carts
          </button>
        </div>

      </div>

      <div class="column column-2">
        <div class="contact-image">
          <img src="@/assets/truck3.jpg" alt="LRS Truck" class="responsive-image">
        </div>
        <br>
        <h3b class="h3b">CART SELECTION</h3b>
        <br>
        <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/form/yard-waste';">Request Free Optional<br>Organics Cart<br>(Food Scraps & Yard Waste)</button><br><br>
          <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/form/sustainable';">Reduced Waste <br>35-Gallon Waste Cart</button><br><br>
            <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/form/cart-enrollment';">Upsize to 96-Gallon & Request Additional Cart(s)</button><br><br>

            <br>
        <h3b class="h3b">SUPPORT & SERVICE REQUESTS </h3b>
        <br>

            <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/missed';">Missed Pickup?</button><br><br>
        <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/damaged';">Replace Damaged<br>or Broken Carts</button><br><br>
          <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/ewaste';">Schedule Electronic<br>Waste Pickup</button><br><br>
          <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/hhw';">Schedule Household<br>Hazardous Waste Pickup</button><br><br>
</div>
    </section>

    <section class="section-2">
      <hr class="horizontal-line">
    </section>

    <section class="section-3">
      <div class="column column-3">
        <h3a class="h3a"><strong>City of Elgin</strong></h3a><br>
      
          <button 
            class="lrs-button" 
            style="width: 275px;" 
            onclick="window.open('tel:847-931-6001');">
            By Phone at<br>ELGIN 311 / 847.931.6001
        </button><br><br>            

        <button 
            class="lrs-button" 
            style="width: 275px;" 
            onclick="window.open('mailto:elgin311@cityofelgin.org', '_blank');">
            By Email at<br>elgin311@cityofelgin.org
        </button>
    
        </div>

      <div class="column column-4">
        <h3a class="h3a"><strong>LRS</strong></h3a><br>

        <button 
            class="lrs-button" 
            style="width: 275px;" 
            onclick="window.open('tel:855-612-7938');">
            By Phone at<br>855.612.7938
        </button><br><br>            

        <button 
            class="lrs-button" 
            style="width: 275px;" 
            onclick="window.open('mailto:elginservice@lrsrecycles.com', '_blank');">
            By Email at<br>ElginService@LRSrecycles.com
        </button><br><br>

        <button 
            class="lrs-button2" 
            style="width: 275px;" 
            onclick="window.open('https://payment.lrsrecycles.com/portal/templanding/', '_blank');">
            LRS Online Bill Pay
        </button>
        </div>


      <div class="column column-5">
        <h3a class="h3a"><strong>Download Brochure</strong></h3a><br>
        <p>English/Spanish Version</p>
        <a href="/ElginBroEng.pdf" target="_blank" rel="noopener noreferrer"><img src="@/assets/broEng.png" alt="Elgin Brochure" class="image"></a>
        
      </div>
    </section>

    <!-- Cart Size Modal -->
    <cart-size-modal
      :show="showCartSizeModal"
      @close="handleModalClose"
      @success="handleModalSuccess"
      @error="handleModalError"
    />
  </div>
</template>

<script>
import CartSizeModal from '@/components/ConfirmCartSizeModal.vue';

export default {
  name: 'HomePage',
  components: {
    CartSizeModal
  },
  data() {
    return {
      showCartSizeModal: false
    }
  },
  methods: {
    handleModalClose() {
      this.showCartSizeModal = false;
    },
    handleModalSuccess() {
      this.showCartSizeModal = false;
      // You can customize the success message or use a toast notification
      alert('Thank you for confirming your cart size!');
    },
    handleModalError(error) {
      console.error('Modal submission error:', error);
      alert('There was an error processing your request. Please try again.');
    },
  },
  mounted() {
    document.querySelectorAll('.accordion-header').forEach((header) => {
      header.addEventListener('click', () => {
        const item = header.parentElement;
        const content = item.querySelector('.accordion-content');

        if (content.style.display === 'block') {
          content.style.display = 'none';
          header.querySelector('.toggle-icon').textContent = '+';
        } else {
          content.style.display = 'block';
          header.querySelector('.toggle-icon').textContent = '-';
        }
      });
    });
  }
};
</script>

<style scoped>


/* Section Layouts for Different Sizes */
.section-1,
.section-3 {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

/* Columns Configuration */
.column {
  box-sizing: border-box;
  padding: 20px;
  flex: 1 1 auto;
  min-width: 200px;
  /* Ensure columns do not shrink too much */
}

.column-1 {
  flex: 1 1 60%;
}

.column-2 {
  flex: 1 1 35%;

}

.center-image-container {
  text-align: center;
}


/* Add new styles for the cart confirmation section */
.cart-confirmation-section {
  margin: 2rem 0;
  text-align: center;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 2px solid var(--lrs-blue);
}

.confirm-size-button {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: var(--lrs-blue);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.confirm-size-button:hover {
  background-color: #005299;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.confirm-size-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


/* Responsive Column Adjustments */
@media (max-width: 768px) {

  .column-1,
  .column-2,
  .column-3,
  .column-4,
  .column-5 {
    flex: 1 1 100%;
    text-align: left;
    /* Adjust text alignment for mobile */
  }
.h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 32px;
    color: var(--lrs-blue);
}

.h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 22px;
    color: var(--recycle-green);
    margin-top: 10px;
    margin-bottom: 10px;
}
.subheading-1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: var(--safety-orange);
}
.h3a {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: var(--lrs-blue);
}
.h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: var(--recycle-green);
}
.accordion-content {
    display: none; /* Collapsed by default */
  }

  .accordion-header {
    cursor: pointer; /* Make the header clickable */
  }
}

/* Section Divider */
.section-2 {
  margin: 20px 0;
}

.horizontal-line {
  height: 5px;
  background-color: var(--lrs-blue);
  border: none;
}

/* Cards Styling and Responsive Configurations */
.card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  flex: 1 1 auto;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Card Titles, Descriptions, and Links */
.card-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--lrs-blue);
  margin-bottom: 10px;
}

.card-description {
  font-size: 16px;
  color: #555555;
  margin-bottom: 20px;
  line-height: 1.5;
}

.card-link {
  font-size: 16px;
  font-weight: 600;
  color: var(--recycle-green);
  text-decoration: none;
  transition: color 0.3s ease;
}

.card-link:hover {
  color: var(--safety-orange);
}

/* Contact Buttons Styles */
.contact-button {
  width: 100%;
  max-width: 275px;
}

/* Image Styles for Responsiveness */
.responsive-image {
  max-width: 566px;
  height: auto;
}

/* List Styling */
.list {
  list-style: inside;
  padding-left: 20px;
  margin-bottom: 20px;
}

/* Section Adjustment */
.section-3 {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

/* Column Styles for Section 3 */
.column-3,
.column-4,
.column-5 {
  flex: 1 1 30%;
  text-align: left;
}

/* Media Queries for Smaller Devices */
@media (max-width: 768px) {

  .section-1,
  .section-3 {
    flex-direction: column;
    align-items: center;
  }

  .column {
    width: 100%;
  }

  .card {
    width: 90%;
    margin: 10px 0;
  }

  .elgin-resident-portal {
    padding: 10px;
  }
}
.accordion2 {
    margin-top: 0px;
    position: left; /* Allows placement relative to the container */
    vertical-align: top;
    width: 600px;
}

.accordion2-item {
    border-bottom: 1px solid #eee;
}

.accordion2-header {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center vertically */
    justify-content: left; /* Center horizontally */
    cursor: none;
    padding: 5px 0;
    padding-left: 10px;
    background-color: #A1B5CB;
}

.accordion2-header .icon {
    font-size: 20px;
    margin-right: 10px;
}

.accordion2-content {
    display: block; /* Ensures content is always visible */
    padding: 10px 0;
    color: black;
    font-size: 16px;
    padding-left: 15px;
}
.accordion {
    margin-top: 0px;
    position: left; /* Allows placement relative to the container */
    vertical-align: top;
    width: 100%;
    max-width: 600px;

  }
  
  .accordion-item {
    border-bottom: 1px solid #eee;
  }

  .accordion-item2 {

    margin: 5px;
    border: 2px solid var(--lrs-blue);
    border-radius: 8px;
    overflow: hidden;
    margin-top: 0px;
    position: left; /* Allows placement relative to the container */
    vertical-align: top;
    width: 100%;
    max-width: 600px;
    padding: 5px 20px;
    border-radius: 8px;
    background-color: #E7EDF2;

  }
  
  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: left;
    cursor: pointer;
    padding: 10px 0;
  }
  
  .accordion-header .icon {
    font-size: 20px;
    margin-right: 10px;
  }
  
  .accordion-header span {
    flex: 1;
    font-weight: bold;
    color: #333;
  }
  
  .accordion-header .toggle-icon {
    font-size: 18px;
    color: #153683;
  }
  
  .accordion-content {
    display: block; /* Ensures content is always visible */
    display: none;
    padding: 10px 0;
    color: black;
    font-size: 16px;
  }
  .h3d {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: var(--lrs-blue);
    text-shadow: 1px;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
  max-width: 600px;
  margin: auto;
  text-align: center;
}

.grid-header {
  font-weight: bold;
  background-color: #ddd;
  padding: 10px;
}

.grid-item {
  padding: 10px;
  border: 1px solid #ccc;
}
</style>
