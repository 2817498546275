<!-- CustomerService.vue -->
<template>
  <div class="form-portal">
    <div class="portal-container">
      <!-- Main Options View -->
      <div v-if="!currentView" class="main-options">
        <!-- Header -->
        <div class="form-header">
          <h1>Customer Service Options</h1>
          <p class="subtitle">Choose an action to perform:</p>
        </div>
  
        <!-- Action Cards -->
        <div class="service-grid">
          <!-- Add New Customer Address -->
          <div class="service-card" @click="setView('newAddress')">
            <div class="card-content">
              <h3>Create New Customer Address</h3>
              <p>Add a new address and contact information for the customer.</p>
              <div class="arrow-icon">
                <i class="fas fa-arrow-right"></i>
              </div>
            </div>
          </div>

          <!-- Cart Size Confirmation -->
          <div class="service-card" @click="setView('cartConfirmation')">
            <div class="card-content">
              <h3>Cart Size Confirmation</h3>
              <p>Verify and confirm customer cart sizes during route delivery.</p>
              <div class="arrow-icon">
                <i class="fas fa-arrow-right"></i>
              </div>
            </div>
          </div>


  
          <!-- Update Customer Request (commented out) -->
          <!--
          <div class="service-card" @click="setView('updateRequest')">
            <div class="card-content">
              <h3>Update Customer Request</h3>
              <p>Modify information for an existing customer request.</p>
              <div class="arrow-icon">
                <i class="fas fa-arrow-right"></i>
              </div>
            </div>
          </div>
          -->
        </div>
      </div>

      <!-- Component Views -->
      <div v-else class="component-view">
        <!-- Back Button -->
        <button @click="backToOptions" class="back-button">
          <i class="fas fa-arrow-left"></i>
          Back to Service Options
        </button>

        <!-- Render the selected component -->
        <AddNewCustomerAddress
          v-if="currentView === 'newAddress'"
          @close="backToOptions"
        />
        <CartSizeConfirmation
          v-if="currentView === 'cartConfirmation'"
          @close="backToOptions"
        />

        <UpdateCustomerRequest
          v-if="currentView === 'updateRequest'"
          @close="backToOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AddNewCustomerAddress from "@/components/AddNewCustomerAddress.vue";
import UpdateCustomerRequest from "@/components/UpdateCustomerRequest.vue";
import CartSizeConfirmation from "@/components/CartSizeConfirmation.vue";


export default {
  name: "CustomerService",
  components: {
    AddNewCustomerAddress,
    UpdateCustomerRequest,
    CartSizeConfirmation,
  },
  data() {
    return {
      currentView: null
    };
  },
  methods: {
    setView(view) {
      this.currentView = view;
    },
    backToOptions() {
      this.currentView = null;
    }
  }
};
</script>

<style scoped>
/* Your existing styles remain unchanged */
.form-portal {
  min-height: 100vh;
  background-color: var(--bg-color, #f8f9fa);
  padding: 1rem;
}

.portal-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.form-header {
  text-align: center;
  margin-bottom: 3rem;
}

.form-header h1 {
  font-size: 2rem;
  font-weight: 700;
  color: var(--primary-color, #333);
  margin-bottom: 0.5rem;
}

.subtitle {
  color: var(--text-secondary, #666);
  font-size: 1.1rem;
}

.service-grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.service-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  padding: 1.5rem;
}

.service-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.card-content h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.card-content p {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: var(--text-secondary, #666);
}

.arrow-icon {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.25rem;
  color: var(--primary-color, #333);
}

/* Component View Styles */
.component-view {
  position: relative;
  padding-top: 3rem;
}

.back-button {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-weight: 500;
  color: var(--primary-color, #333);
  transition: all 0.2s ease;
}

.back-button:hover {
  background-color: #f8f9fa;
  border-color: #ccc;
}

.back-button i {
  font-size: 1rem;
}

/* Transition animations */
.component-view {
  animation: slideIn 0.3s ease;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
